<template>
  <div>这里是404</div>
</template>

<script>

export default {
  name: 'Page404'
}
</script>
